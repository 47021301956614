@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%; /**왜 111% 인지 모르겟다.**/
  }
  #dash,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%; /**왜 111% 인지 모르겟다.**/
  }

  #portfolio {
    width: 100%; /**왜 110ㄴ% 인지 모르겟다.**/
  }
}
